import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import * as Sentry from '@sentry/nextjs'

import Layout from './layout/Layout'
import IntroForm from './IntroForm/IntroForm'
import DownloadImageButton from './DownloadImageButton'
import DownloadVideoButton from './DownloadVideoButton/DownloadVideoButton'
import mapCapitalLetters from './mapCapitalLetters'
import TextChangedModal from './TextChangedModal'
import NotFoundModal from './NotFoundModal'
import ProductHuntBadge from './ProductHuntBadge'
import useIntroData, { createIntro } from './useIntroData'

const IntroPreview = dynamic(() => import('./IntroPreview/IntroPreview'), {
  ssr: false,
})

export default function AppContent({
  previewImage,
  download,
  notFoundIntro,
}) {
  const router = useRouter()
  const { data: intro, loading } = useIntroData(router.query.code)
  const [currentFormValues, setCurrentFormValues] = useState(intro)

  const handleSubmit = useCallback(async (newTitles) => {
    try {
      const isSameText = (
        newTitles.title1 === intro.title1
        && newTitles.title2 === intro.title2
      )

      if (isSameText) {
        return
      }
      Sentry.addBreadcrumb({
        message: JSON.stringify({
          title1: newTitles.title1,
          title2: newTitles.title2,
        }),
      })
      const code = await createIntro(newTitles.title1, newTitles.title2)
      router.push(`/${code}`, null, { shallow: true })
    } catch (error) {
      Sentry.captureException(error)
      throw error
    }
  }, [intro])

  const handleFormChanges = useCallback(async (newTitles) => {
    setCurrentFormValues({
      title1: newTitles.title1,
      title2: newTitles.title2,
    })
  }, [setCurrentFormValues])

  const [showTextChangedModal, setShowTextChangedModal] = useState(false)
  const [showNotFoundModal, setShowNotFoundModal] = useState(false)

  useEffect(() => {
    if (notFoundIntro) {
      setShowNotFoundModal(true)
    }
  }, [])

  const alertTextChanged = useCallback(() => {
    setShowTextChangedModal(true)
  }, [setShowTextChangedModal])

  // TODO, migrate to call formik submit
  const confirmGenerateNewLogo = useCallback(() => {
    setShowTextChangedModal(false)
    handleSubmit(currentFormValues)
  }, [setShowTextChangedModal, handleSubmit, currentFormValues])

  return (
    <Layout
      previewImage={previewImage}
    >
      <Box display="flex" flexDirection="column" alignItems="center" pb={4} p={2}>
        <h1 className="title">
          {mapCapitalLetters('Breaking Bad Intro Creator')}
        </h1>
        <Typography variant="subtitle">
          First, type your text on the fields below. Click on the button to generate a logo.
        </Typography>
        <Typography variant="subtitle">
          Check your preview, then you can download it as an Image or Video.
        </Typography>
        {!loading && (
          <>
            <Box mt={2} display="flex" width="100%" maxWidth="500px">
              <IntroForm
                initialValues={intro}
                onSubmit={handleSubmit}
                onChange={handleFormChanges}
              />
            </Box>
            <Box mt={2}>
              <IntroPreview
                title1={intro.title1}
                title2={intro.title2}
              />
            </Box>
          </>
        )}
        <Box
          mt={2}
          display="flex"
          gap={2}
          justifyContent="center"
          flexWrap="wrap"
        >
          <DownloadVideoButton
            intro={intro}
            initiallyOpened={download}
            currentFormValues={currentFormValues}
            alertTextChanged={alertTextChanged}
          />
          <DownloadImageButton
            intro={intro}
            currentFormValues={currentFormValues}
            alertTextChanged={alertTextChanged}
          />
        </Box>
        <ProductHuntBadge />
      </Box>
      <TextChangedModal
        open={showTextChangedModal}
        onClose={() => setShowTextChangedModal(false)}
        onConfirm={confirmGenerateNewLogo}
      />
      <NotFoundModal
        open={showNotFoundModal}
        onClose={() => setShowNotFoundModal(false)}
      />
      <style jsx>
        {`
        .title {
          font-family: BreakingCooper;
          font-size: 80px;
          font-weight: 500;
          text-align: center;
          line-height: 1em;
          margin: 20px 0;
          color: white;
          font-weight: 600;

          > :global(.capital) {
            font-weight: 500;
          }

          @media screen and (max-width: 768px) {
            font-size: 3em;
          }
        }
        `}
      </style>
    </Layout>
  )
}

AppContent.propTypes = {
  previewImage: PropTypes.string,
  download: PropTypes.bool,
  notFoundIntro: PropTypes.bool,
}
