import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import Modal from './Modal'

export default function DownloadVideoModal({ open, onClose, onConfirm }) {
  return (
    <Modal
      title="Download Video"
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <Box px={3} maxWidth="900px">
        <p>
          Now you can order your logo as a Breaking Bad intro video!
        </p>
        <p>
          Your video will be generated in high quality.
          Check this sample below featuring our logo &quot;Kassel Labs&quot;:
        </p>
        <iframe
          className="iframe"
          src="https://www.youtube.com/embed/gl3KIZE-h44?autoplay=1&color=white&listType=user_uploads&rel=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
        <p>
          Would you like to have a custom made video with the words you&apos;ve
          entered in the image preview?
        </p>
      </Box>
      <Box display="flex" justifyContent="center" px={3} py={2}>
        <Button
          variant="contained"
          onClick={onConfirm}
        >
          Yes, buy video now
        </Button>
      </Box>
      <style jsx>
        {`
        .iframe {
          width: 100%;
          aspect-ratio: 16 / 9;
        }
        `}
      </style>
    </Modal>
  )
}

DownloadVideoModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}
