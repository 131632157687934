import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import Modal from './Modal'

export default function AlertDefaultIntroModal({ open, onConfirm, onClose }) {
  return (
    <Modal
      title="Downloading Default Text"
      open={open}
      onClose={onClose}
    >
      <Box px={3} textAlign="center">
        <p>
          You are trying to download an intro with the default text.
          <br />
          Edit your text first, then download an intro with your own custom text.
        </p>
      </Box>
      <Box display="flex" justifyContent="center" px={3} py={2}>
        <Button
          variant="contained"
          onClick={onConfirm}
        >
          Ok, change text
        </Button>
      </Box>
    </Modal>
  )
}

AlertDefaultIntroModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}
