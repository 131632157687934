import React from 'react'

export default function ProductHuntBadge() {
  return (
    <div className="product-hunt-badge">
      <a
        className="link"
        href="https://www.producthunt.com/posts/breaking-bad-intro-creator?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-breaking&#0045;bad&#0045;intro&#0045;creator"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="image"
          src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=338650"
          alt="Breaking&#0032;Bad&#0032;Intro&#0032;Creator - Create&#0032;your&#0032;customized&#0032;Breaking&#0032;Bad&#0032;opening&#0032;animation | Product Hunt"
          width="250"
          height="54"
        />
      </a>
      <style jsx>
        {`
        .product-hunt-badge {
          position: fixed;
          bottom: 10px;
          left: 16px;

          > .link > .image {
            width: 250px;
            height: 54px;
          }

          @media screen and (max-width: 1365px) {
            position: initial;
            display: flex;
            justify-content: center;
            padding-top: 16px
          }
        }
        `}
      </style>
    </div>
  )
}
