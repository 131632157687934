import React from 'react'
import PropTypes from 'prop-types'

import NextHead from 'next/head'

const TITLE = 'Breaking Bad Intro Creator'
const DESCRIPTION = 'Create your own customized Breaking Bad Intro!'

export default function Head({
  previewImage,
}) {
  return (
    <NextHead>
      <title>{ TITLE }</title>
      <meta charSet="utf-8" />
      <meta property="og:title" content={TITLE} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={DESCRIPTION} />
      {previewImage && (
        <meta property="og:image" content={previewImage} />
      )}
      <meta property="twitter:title" content={TITLE} />
      <meta property="twitter:description" content={DESCRIPTION} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@kassellabs" />
      {previewImage && (
        <meta property="twitter:image" content={previewImage} />
      )}
    </NextHead>
  )
}

Head.propTypes = {
  previewImage: PropTypes.string,
}
