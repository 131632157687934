import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'

import Button from './Button'
import ScreenshotIntroPreview from './ScreenshotIntroPreview'
import useNavigator from './useNavigator'
import downloadScreenshotFromApi from './downloadScreenshotFromApi'
import downloadScreenshotFromBrowser from './downloadScreenshotFromBrowser'

const downloadImage = async (intro, renderScreenshotLocal, useLocationHref) => {
  if (renderScreenshotLocal) {
    await downloadScreenshotFromBrowser(intro)
    return
  }

  await downloadScreenshotFromApi(intro, useLocationHref)
}

export default function DownloadImageButton({ intro, currentFormValues, alertTextChanged }) {
  const { title1, title2 } = intro

  const { renderScreenshotLocal, isChromeIos } = useNavigator()
  const [isTakingScreenshot, setIsTakingScreenshot] = useState(false)
  const isTakingLocalScreenshot = renderScreenshotLocal && isTakingScreenshot
  const isTakingApiScreenshot = !renderScreenshotLocal && isTakingScreenshot

  const onDownloadImageClick = useCallback(async () => {
    const isTextChanged = (
      currentFormValues.title1 !== intro.title1
      || currentFormValues.title2 !== intro.title2
    )

    if (isTextChanged) {
      alertTextChanged()
      return
    }

    setIsTakingScreenshot(true)
    const useLocationHref = isChromeIos
    await downloadImage(intro, renderScreenshotLocal, useLocationHref)
    setIsTakingScreenshot(false)
  }, [intro, isChromeIos])

  return (
    <>
      {isTakingApiScreenshot && (
        <p style={{ margin: '0' }}>
          It could take some seconds to generate your image...
        </p>
      )}
      <Button
        loading={isTakingScreenshot}
        variant="contained"
        onClick={onDownloadImageClick}
        startIcon={<PhotoCameraIcon />}
      >
        Download Image
      </Button>
      {isTakingLocalScreenshot && (
        <ScreenshotIntroPreview
          loading={isTakingScreenshot}
          title1={title1}
          title2={title2}
          hidden
        />
      )}
    </>
  )
}

DownloadImageButton.propTypes = {
  intro: PropTypes.object,
  currentFormValues: PropTypes.object,
  alertTextChanged: PropTypes.func,
}
