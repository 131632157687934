import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import IntroPreview from './IntroPreview/IntroPreview'

export default function ScreenshotIntroPreview({ title1, title2, hidden }) {
  const elementWidth = useMemo(() => {
    // using pixelRatio to adjust screenshot resolution correctly
    // (e.g. using zoom on browser or screen with pixelRatio > 1, like cellphones)
    const pixelRatio = window?.devicePixelRatio || 1
    return 1600 / pixelRatio
  }, [])

  return (
    <div
      id="intro-preview-screenshot"
      className={classnames('screenshot-intro-preview', { '-hidden': hidden })}
    >
      <IntroPreview
        isStatic
        title1={title1}
        title2={title2}
        style={{ width: `${elementWidth}px` }}
      />
      <style jsx>
        {`
        .screenshot-intro-preview {
          position: absolute;
          top: 0;
          left: 0;
          pointer-events: none;

          &.-hidden {
            opacity: 0;
          }

          :global(.intro-preview) {
            border: none !important;
            border-radius: 0 !important;
          }
        }
        `}
      </style>
    </div>
  )
}

ScreenshotIntroPreview.propTypes = {
  title1: PropTypes.string.isRequired,
  title2: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
}
