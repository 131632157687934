import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'

import Modal from './DownloadVideoButton/Modal'

export default function TextChangedModal({ open, onConfirm, onClose }) {
  return (
    <Modal
      title="Text was changed"
      open={open}
      onClose={onClose}
    >
      <Box px={3} textAlign="center">
        <p>
          The logo texts was changed but you didn&apos;t generate a new logo.
          Do you want to generate a new logo before download?
        </p>
      </Box>
      <Box display="flex" justifyContent="center" px={3} py={2}>
        <Button
          variant="contained"
          onClick={onConfirm}
        >
          Ok, generate new logo
        </Button>
      </Box>
    </Modal>
  )
}

TextChangedModal.propTypes = {
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
}
