import * as htmlToImage from 'html-to-image'
import download from 'downloadjs'

import getDownloadFileName from './getDownloadFileName'

const waitUntilElementExists = (selector) => new Promise((resolve) => {
  let intervalId = null

  const resolveIfExists = () => {
    const element = document.querySelector(selector)
    if (element) {
      clearInterval(intervalId)
      resolve(element)
    }
  }

  intervalId = setInterval(resolveIfExists, 100)
  resolveIfExists()
})

export const waitUntilSmokeIsRendered = () => (
  waitUntilElementExists('#intro-preview-screenshot .intro-preview .smoke[ready=true]')
)

const downloadScreenshotFromBrowser = async (intro) => {
  // Wait the element to be screenshot to exist
  const filename = getDownloadFileName(intro)

  const elementToScreenshot = await waitUntilElementExists(
    '#intro-preview-screenshot .intro-preview',
  )

  // Wait a bit more until the smoke has been rendered in the canvas
  await waitUntilSmokeIsRendered()

  const dataURL = await htmlToImage.toPng(elementToScreenshot, { quality: 1 })
  download(dataURL, filename, 'image/png')
}

export default downloadScreenshotFromBrowser
