import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import { Formik, useFormikContext } from 'formik'

import Button from '../Button'
import TextInputField from './TextInputField'

// TODO improve this by listening with ref on formik instead an inside component
const OnChangeListener = ({ onChange }) => {
  const { values } = useFormikContext()

  useEffect(() => {
    onChange(values)
  }, [values])

  return null
}

OnChangeListener.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default function IntroForm({ initialValues, onSubmit, onChange }) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      handleChange={onChange}
    >
      {({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <OnChangeListener onChange={onChange} />
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
          >
            <TextInputField
              name="title1"
              label="First Title"
              maxLength={64}
            />
            <TextInputField
              name="title2"
              label="Second Title"
              maxLength={64}
            />
            <Box display="flex" justifyContent="center">
              <Button
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                Generate Logo
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}

IntroForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}
