const getDownloadFileName = (intro) => {
  const { title1, title2 } = intro
  const isAtLeastOneTitleEmpty = title1 === '' || title2 === ''
  const singleTitle = title1 || title2
  const filename = isAtLeastOneTitleEmpty
    ? `${singleTitle}.png`
    : `${title1}-${title2}.png`

  return filename
}

export default getDownloadFileName
